export const onBeforeunload = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  event.returnValue = '記事を編集中です'
}

export const setBeforeUnloadEvent = () => {
  window.addEventListener('beforeunload', onBeforeunload)
}

export const removeBeforeUnloadEvent = () => {
  window.removeEventListener('beforeunload', onBeforeunload)
}
