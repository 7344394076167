import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTracker } from '@pocake/note-custom-hooks'
import { useAuthState } from 'contexts/AuthContext'
import { isAvailableUser } from 'utils/isAvailableUser'
import { removeBeforeUnloadEvent } from 'utils/onBeforeunload'

export default function Top() {
  const router = useRouter()
  const authState = useAuthState()
  const { setPvUuidToURL } = useTracker()

  useEffect(() => {
    ;(async () => {
      const { currentUser } = authState
      if (currentUser === null) {
        return
      }

      if (isAvailableUser(currentUser) === false) {
        const url = setPvUuidToURL(
          process.env.NOTE_FRONT_URL ?? 'https://note.com'
        )
        removeBeforeUnloadEvent()
        window.location.replace(url)
        return
      }

      router.replace('/new')
    })()
  }, [authState, router, setPvUuidToURL])

  return null
}
